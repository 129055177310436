import TextField from '../../TextField'
import { CircularProgress, InputAdornment } from '@mui/material'

export default function LoadingTextField() {
  return (
    <TextField
      disabled
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        ),
      }}
    />
  )
}
