// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const UNIT_OF_MEASURES_OPTIONS = {
  pieces: 2,
}

const ICMS_SP = 0.82
const ICMS_SP_PERCENT = 18

export const getUrlKey = (key) => {
  const param = new URLSearchParams(window.location.search).get(key)
  return param
}

const percentage = (value) => value / 100

/**
 * @typedef Config
 * @prop {number} quantity - Multiplicador usado para calcular os totais de cada imposto.
 * @prop {number} unitValue - Base
 * @prop {number} IPI_PERCENTAGE - Porcentagem do IPI (Imposto sobre Produtos Industrializados)
 * @prop {number} ICMS_PERCENTAGE - Porcentagem do ICMS (Imposto sobre Circulação de Mercadorias e Serviço)
 * @prop {boolean} disableCalculateRateDifference - Booleano que desabilita o cálculo do DIFAL (diferencial de aliquota)
 *
 * @param {Config} params
 * @returns
 */
export const calculateTaxes = ({
  quantity,
  unitValue,
  IPI_PERCENTAGE,
  ICMS_PERCENTAGE,
  disableCalculateRateDifference = false,
}) => {
  const ignoreRateDifference =
    disableCalculateRateDifference ||
    ICMS_PERCENTAGE === ICMS_SP_PERCENT ||
    ICMS_PERCENTAGE === 0

  if (ignoreRateDifference) {
    const ipi = unitValue * percentage(IPI_PERCENTAGE)
    const taxes = ipi + unitValue

    return {
      ipi: ipi,
      difal: 0,
      base: unitValue,
      taxes,

      ipi_total: ipi * quantity,
      difal_total: 0,
      base_total: unitValue * quantity,
      taxes_total: taxes * quantity,
    }
  } else {
    // 1. Extrair a base de calculo
    const calcBase = unitValue

    // 2. Remover da base de calculo a porcentagem do ICMS
    const calcBaseSubtractedICMS =
      calcBase - calcBase * percentage(ICMS_PERCENTAGE)

    // 3. Dividir calculo pela porcentagem padrão do estado
    const balanced = calcBaseSubtractedICMS / ICMS_SP

    // 4. Calcular o valor do imposto
    const ipi = balanced * percentage(IPI_PERCENTAGE)
    const taxes = balanced + ipi

    // 5. Calcular a diferença do imposto
    const difal = taxes - calcBase - ipi

    return {
      ipi: ipi,
      difal: difal,
      base: calcBase,
      taxes,

      ipi_total: ipi * quantity,
      difal_total: difal * quantity,
      base_total: calcBase * quantity,
      taxes_total: taxes * quantity,
    }
  }
}

export const getQuantity = (quantity, unitOfMeasure) => {
  if (unitOfMeasure === UNIT_OF_MEASURES_OPTIONS.pieces) {
    return parseInt(quantity, 10)
  }

  return quantity
}

export const compact = (values) =>
  values
    .filter(
      (item) => item !== undefined && item !== null && item.trim().length > 0
    )
    .join(' - ')
