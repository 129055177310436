import React, { useState, useEffect } from 'react'
import TextField from '../../TextField'
import LoadingTextField from '../../utils/LoadingTextField'

import { apiEndPoints } from '../../../consts/apiEndPoints'
import api from '../../../services/api'
import handleErrors from '../../../services/handleErrors'

export default function PaymentConditionCombobox({ name, defaultValue }) {
  const [paymentConditions, setPaymentConditions] = useState([])

  useEffect(() => {
    api
      .get(apiEndPoints.paymentConditions)
      .then((response) =>
        setPaymentConditions(
          response.data.map((paymentCondition) => ({
            value: String(paymentCondition.id),
            label: paymentCondition.descr || String(paymentCondition.id),
          }))
        )
      )
      .catch(handleErrors)
  }, [])

  if (paymentConditions.length === 0) {
    return <LoadingTextField />
  }

  return (
    <TextField
      fullWidth
      name={name}
      defaultValue={String(defaultValue)}
      size="small"
      select
      SelectProps={{
        native: true,
      }}
    >
      {paymentConditions.map((paymentCondition, index) => (
        <option key={index} value={paymentCondition.value}>
          {paymentCondition.label}
        </option>
      ))}
    </TextField>
  )
}
