import styled from 'styled-components'
import { Box, Grid } from '@mui/material'

export const StyledGrid = styled(Grid)`
  &.MuiGrid-container {
    /* margin-top: 24px; */
  }

  @media print {
    &.MuiGrid-container {
      display: flex !important;

      div.MuiGrid-item {
        flex: 1 !important;
      }
    }

    .BiddingFinishForm-total {
      min-width: auto !important;
      border-left: none;
      border-right: none;
    }
  }
`

export const Card = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${(props) => props.theme.palette.grey[100]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 8px;
  padding: 1rem;

  .MuiFormControl-root {
    background: ${(props) => props.theme.palette.background.paper};
    margin-top: 14px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    align-items: center;
  }

  @media print {
    border: none;
  }
`
