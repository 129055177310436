import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material'
import { ArrowDropUp } from '@mui/icons-material'

import {
  numberTextMaskProps,
  removeNumberMask,
  applyNumberMask,
} from '../../services/masks'
import { getQuantity, calculateTaxes } from '../../services/utils'

import { Container } from './styles'
import TextField from '../TextField'

export default function BiddingPrice({ onEdit, onUpdate, biddingPrice }) {
  const isMobile = useMediaQuery('@media only screen and (max-width: 899.95px)')

  const { bidding_item: biddingItem } = biddingPrice
  const available = biddingPrice.available ?? true

  const classificationText = [
    biddingItem.item?.dsc_item_marca_1,
    biddingItem.item?.dsc_item_marca_2,
    biddingItem.item?.dsc_item_marca_3,
  ].join(' ')

  const quantity = getQuantity(
    biddingItem.ittp_qtdade,
    biddingItem.unit_of_measure.id_un_medidas
  )

  const disableCalculateRateDifference = biddingPrice.ignore_rate_difference

  const getTotal = () => {
    const unitValue = removeNumberMask(biddingPrice.ptp_valor_prev)
    const ipi = removeNumberMask(biddingPrice.ptp_ipi_percent)
    const icms = removeNumberMask(biddingPrice.ptp_icms_percent)

    return calculateTaxes({
      quantity,
      unitValue,
      ICMS_PERCENTAGE: icms,
      IPI_PERCENTAGE: ipi,
      disableCalculateRateDifference,
    })
  }

  const handleChange = (name) => (e) => {
    const value = e.target.value

    const updatedBiddingPrice = {
      ...biddingPrice,
      [name]: value,
    }

    onUpdate(updatedBiddingPrice)
  }

  const handleChangeAvailable = () => {
    onUpdate({ ...biddingPrice, available: !available })
  }

  const getInputProps = (name, { ...props } = {}) => ({
    name,
    size: 'small',
    onChange: handleChange(name),
    value: biddingPrice[name] ?? '',
    disabled: !available || props.disabled,
  })

  const { taxes, taxes_total } = getTotal()

  return (
    <Container className="BiddingItem-Container">
      <Box className="BiddingItem-Data">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={available}
              onChange={handleChangeAvailable}
            />
          }
          label={
            <Typography variant="body1" fontWeight="bold">
              {biddingItem.dsc_materiais}
            </Typography>
          }
        />

        <Typography variant="body2" pl="28px" gutterBottom>
          {biddingItem.cod_mat}
        </Typography>

        {biddingItem.dsc_materiais_compl && (
          <Typography variant="body2" color="text.secondary" pl="28px">
            Complemento: {biddingItem.dsc_materiais_compl}
          </Typography>
        )}

        {classificationText.trim() && (
          <Typography variant="body2" color="text.secondary" pl="28px">
            Marca: {classificationText}
          </Typography>
        )}

        {isMobile && (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => onEdit(biddingPrice)}
            endIcon={<ArrowDropUp />}
            sx={{
              marginTop: '1rem',
            }}
          >
            Informar preço
          </Button>
        )}
      </Box>

      <Box className={clsx('BiddingItem-Form', { available })}>
        <Box alignItems="flex-end">
          <Typography component="div" variant="caption">
            Qtdade.
          </Typography>
          <Typography variant="h5">{quantity}</Typography>
        </Box>
        <Box>
          <Typography component="label" variant="caption">
            Un.
          </Typography>
          <Typography variant="h5">
            {biddingItem.unit_of_measure.dsc_un_medidas}
          </Typography>
        </Box>
        <Box>
          <Typography component="label" variant="caption">
            Prev.Entrega
          </Typography>
          <TextField
            placeholder="Dias"
            type="number"
            inputMode="numeric"
            inputProps={{
              min: 0,
            }}
            {...getInputProps('ptp_prazo_entrega')}
          />
        </Box>
        <Box minWidth={160}>
          <Typography component="label" variant="caption">
            Valor
          </Typography>
          <TextField
            placeholder="R$"
            inputMode="decimal"
            textMaskProps={numberTextMaskProps()}
            {...getInputProps('ptp_valor_prev')}
          />
        </Box>
        <Box minWidth={100}>
          <Typography component="label" variant="caption">
            % IPI
          </Typography>
          <TextField
            placeholder="%"
            inputMode="decimal"
            textMaskProps={numberTextMaskProps()}
            {...getInputProps('ptp_ipi_percent')}
          />
        </Box>
        <Box minWidth={100}>
          <Typography component="label" variant="caption">
            % ICMS
          </Typography>
          <TextField
            placeholder="%"
            inputMode="decimal"
            textMaskProps={numberTextMaskProps()}
            {...getInputProps('ptp_icms_percent')}
          />
        </Box>

        {/* Totais */}
        <Box alignItems="flex-end">
          <Typography component="label" variant="caption">
            Valor Final
          </Typography>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth={150}
            variant="h5"
          >
            {applyNumberMask(taxes)}
          </Typography>
        </Box>
        <Box alignItems="flex-end">
          <Typography component="label" variant="caption">
            Total
          </Typography>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth={150}
            variant="h5"
          >
            {applyNumberMask(taxes_total)}
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

BiddingPrice.propTypes = {
  biddingPrice: PropTypes.object.isRequired,
  biddingPriceForPaste: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

BiddingPrice.defaultProps = {}
