import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

export const TextMaskCustom = React.forwardRef(({ mask, ...props }, ref) => {
  return <MaskedInput {...props} mask={mask} placeholderChar={'\u2000'} />
})

TextMaskCustom.propTypes = {
  mask: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
    ),
    PropTypes.func,
  ]).isRequired,
}

TextMaskCustom.defaultProps = {}

export default TextMaskCustom
