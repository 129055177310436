import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'

function Loading(props) {
  if (!props.loading) return null
  return (
    <CircularProgress
      color="primary"
      size={50}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      }}
    />
  )
}

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default Loading
