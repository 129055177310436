import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  min-height: 150px;
  width: 100%;

  margin-top: 40px;
  align-self: flex-end;
  flex-grow: 1;
`
