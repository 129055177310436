import styled from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  min-height: 100px;

  &:has(.BiddingItem-Form:not(.available)) {
    .MuiTypography-root {
      color: ${(props) => props.theme.palette.action.disabled};
    }
  }

  .MuiBox-root {
    border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
    flex: 1;

    h5 {
      margin-top: 20px;
    }

    .MuiFormControl-root {
      margin-top: 20px;
      background-color: #fff;
    }

    &:last-of-type {
      border-right: none;
    }

  }

  .BiddingItem-Data {
    flex: 1;

    @media only screen and (max-width: 899.95px) {
      flex: 12;
      border-right: 0;
    }
  }

  .BiddingItem-Form {
    flex: 2;
    display: flex;

    .MuiBox-root {
      display: flex;
      flex-direction: column;

      padding-top: 6px;
      padding-left: 20px;
      padding-right: 20px;

      &:last-child {
        padding-right: 0;
      }
    }

    @media only screen and (max-width: 899.95px) {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }

  .BiddingItem-Menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  @media print {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e4e4e4;

    .MuiBox-root {
      flex: 1 !important;
      border-right: none;
    }

    .BiddingItem-Form {
      flex: 2 !important;

      .MuiBox-root {
        padding: 5px;
      }
    }
  }
`
