import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import {
  Box,
  Button,
  Grid,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { PrintRounded as Print } from '@mui/icons-material'

import apiEndPoints from '../../consts/apiEndPoints'
import api from '../../services/api'
import { applyNumberMask, removeNumberMask } from '../../services/masks'
import { getQuantity, calculateTaxes, getUrlKey } from '../../services/utils'
import { copy } from '../../services/copy'
import handleErrors from '../../services/handleErrors'
import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import BiddingList from '../../components/BiddingList'
import BiddingFinishForm from '../../components/BiddingFinishForm'
import { StyledContainer } from './styles'

const schema = yup.object().shape({
  ftp_frete: yup.string().required(),
  ftp_orcto_nf: yup.string().optional(),
  ftp_obs: yup.string().optional(),
  ftp_validade: yup.string().required(),
  id_condpagto: yup.string().required(),
  supplier_id: yup.string().required(),
  prices: yup
    .array()
    .of(
      yup.object().shape({
        tpre_numero: yup.string().required(),
        id_cia: yup.number().required(),
        id_fornecedor: yup.number().required(),
        id_itens: yup.number().required(),
        ptp_icms_percent: yup.number().required(),
        ptp_ipi_percent: yup.number().required(),
        ptp_prazo_entrega: yup.number().required(),
        ptp_valor_com_imp: yup.number().required(),
        ptp_valor_ipi: yup.number().required(),
        ptp_valor_icms_difal: yup.number().required(),
        ptp_valor_prev: yup.number().required(),
      })
    )
    .min(1),
})

export default function MainPage() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [items, setItems] = useState([])
  const [owner, setOwner] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchBiddings = async () => {
      setErrorMessage(null)

      // Authentication
      api.defaults.headers['apikey'] = getUrlKey('k')

      try {
        const response = await api.get(apiEndPoints.biddingItems)

        if (response.data.length === 0) {
          setErrorMessage({
            severity: 'warning',
            msg: 'Nenhuma licitação foi encontrada',
          })
          return
        }

        const items = response.data.map((item) => ({
          ...item,
          ptp_valor_prev: applyNumberMask(item.ptp_valor_prazo),
          ptp_ipi_percent: applyNumberMask(item.ptp_ipi_percent),
          ptp_icms_percent: applyNumberMask(item.ptp_icms_percent),
        }))

        const owner = items[0].supplier
        owner.tpre_numero = items[0].tpre_numero
        owner.bidding_due_date = items[0].bidding_due_date

        const biddingSupplier = items[0].bidding_supplier
        owner.ftp_frete = biddingSupplier.ftp_frete
        owner.ftp_validade = biddingSupplier.ftp_validade
        owner.ftp_orcto_nf = biddingSupplier.ftp_orcto_nf
        owner.ftp_obs = biddingSupplier.ftp_obs
        owner.id_condpagto = biddingSupplier.id_condpagto

        setOwner(owner)
        setItems(items)

        // Mudar título da página dinamicamente
        document.title = document.title + '| ' + owner.tpre_numero
      } catch (error) {
        setErrorMessage(
          handleErrors(error, `Erro ao buscar a cotação "${error}"!`)
        )
      } finally {
        setLoading(false)
      }
    }
    fetchBiddings()
  }, [])

  const sendBidding = async (params) => {
    try {
      setLoading(true)

      const requestBody = copy(params)
      requestBody.supplier_id = owner.cod_empresa

      ///////////////////////////////////////////////////
      // REMOVER MÁSCARAS E CALCULAR VALOR COM IMPOSTO //
      ///////////////////////////////////////////////////

      const availableItems = items.filter((i) =>
        [true, undefined].includes(i.available)
      )

      requestBody.prices = availableItems.map((price) => {
        const unitOfMeasure = price.bidding_item?.unit_of_measure.id_un_medidas
        const disableCalculateRateDifference = price.ignore_rate_difference

        const quantity = getQuantity(
          price.bidding_item.ittp_qtdade,
          unitOfMeasure
        )

        const unitValue = removeNumberMask(price.ptp_valor_prev)
        const ipiPercent = removeNumberMask(price.ptp_ipi_percent)
        const icmsPercent = removeNumberMask(price.ptp_icms_percent)

        const { taxes, ipi, difal } = calculateTaxes({
          quantity,
          unitValue,
          IPI_PERCENTAGE: ipiPercent,
          ICMS_PERCENTAGE: icmsPercent,
          disableCalculateRateDifference,
        })

        return {
          tpre_numero: price.tpre_numero,
          id_cia: price.id_cia,
          id_itens: price.id_itens,
          id_fornecedor: price.id_fornecedor,
          ptp_prazo_entrega: price.ptp_prazo_entrega,
          ptp_valor_prev: unitValue,
          ptp_ipi_percent: ipiPercent,
          ptp_icms_percent: icmsPercent,
          ptp_valor_ipi: ipi,
          ptp_valor_icms_difal: difal,
          ptp_valor_com_imp: taxes,
        }
      })

      const isValid = await schema.isValid(requestBody)

      if (isValid) {
        await api.post(apiEndPoints.updateBiddingPrices, requestBody)

        setErrorMessage({
          severity: 'success',
          msg: 'Cotação enviada com successo',
        })

        setItems([])
        setOwner({})
      } else {
        setErrorMessage({
          severity: 'warning',
          msg: 'Existem campos obrigatórios não preenchidos',
        })
      }
    } catch (error) {
      const errorMessage = handleErrors(error, 'Erro ao enviar a cotação!')
      setErrorMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <StyledContainer maxWidth="xl">
      <AppBar owner={owner} />
      <section className={clsx('main', { loading })}>
        <Loading loading={loading} />
        <Grid
          container
          rowSpacing={2}
          direction="column"
          sx={{
            marginTop: isMobile ? '1rem' : 0,
          }}
        >
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Box display="flex">
              <Button
                startIcon={<Print />}
                onClick={handlePrint}
                size="small"
                variant="outlined"
              >
                <Typography variant="body2">Imprimir</Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                marginBottom: '40px',
                paddingLeft: '14px',
                borderLeft: '1px solid #C6C6C6',
              }}
            >
              <Typography fontWeight="bold" variant="body1">
                Caro Fornecedor,
              </Typography>
              <Typography gutterBottom variant="body2">
                Favor informar o seu melhor preço para os materiais relacionados
                abaixo. <br />
                Caso não tenha disponibilidade para algum dos items, desmarque a{' '}
                <i>flag</i> ao lado de seu nome.
              </Typography>
            </Box>
            {errorMessage ? (
              <Alert
                severity={errorMessage.severity}
                closeText="Fechar"
                onClose={() => setErrorMessage(null)}
              >
                {errorMessage.msg}
              </Alert>
            ) : (
              isMobile && (
                <Alert severity="info">
                  Clique em cada Material/Serviço para inserir os dados.
                </Alert>
              )
            )}
          </Grid>
          <Grid item xs={12}>
            <BiddingList biddingList={items} onUpdateList={setItems} />
          </Grid>
        </Grid>
        {owner.cod_empresa && (
          <BiddingFinishForm
            owner={owner}
            items={items}
            onSendBidding={sendBidding}
          />
        )}
      </section>
      <Footer />
    </StyledContainer>
  )
}
