import styled from 'styled-components'
import { Container } from '@mui/material'

export const StyledContainer = styled(Container)`
  display: flex !important;
  flex-direction: column;

  min-height: 100vmin;
  background: ${(props) => props.theme.palette.background.paper};
  padding: 0 !important;

  section.main {
    min-height: calc(100vh - 180px - 150px);
    padding-left: 24px;
    padding-right: 24px;

    display: flex;
    flex-direction: column;
    overflow: auto;

    ${(props) => props.theme.breakpoints.down('md')} {
      align-items: center;

      padding-left: 12px;
      padding-right: 12px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  section.main.loading {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
    z-index: 0;
  }
`
