import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@mui/material/TextField'
import TextMaskCustom from '../TextMaskCustom'

function TextField({ textMaskProps, ...props }) {
  return (
    <MuiTextField
      {...props}
      inputProps={{
        ...props.inputProps,
        ...textMaskProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        inputComponent: textMaskProps ? TextMaskCustom : 'input',
        ...props.InputProps,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

TextField.propTypes = {
  textMaskProps: PropTypes.objectOf(PropTypes.any),
}

TextField.defaultProps = {
  textMaskProps: null,
}

export default TextField
