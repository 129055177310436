import React from 'react'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { Box } from '@mui/system'
import { Typography, Grid } from '@mui/material'
import { Container, StyledAppBar, StyledToolbar } from './styles'
import Logo from '../Logo'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const COMPANY_PHONE = '(19) 3429-7000'

const gridItemProps = {
  item: true,
  md: 3,
  xs: 12,
  display: 'flex',
  flexDirection: 'column',
}

export default function AppBar(props) {
  const owner = props.owner

  const getBiddingDueDate = () => {
    return owner.bidding_due_date ? format(parseISO(owner.bidding_due_date), 'dd/MM/yyyy') : ""
  }

  return (
    <Container>
      <StyledAppBar color="transparent" elevation={0} position="relative">
        <StyledToolbar>
          <Grid container direction="row">
            <Grid className="AppBar-Logo" {...gridItemProps}>
              <Box>
                <Logo />
                <Typography variant="body2">Fone: {COMPANY_PHONE}</Typography>
              </Box>
            </Grid>
            <Grid {...gridItemProps}>
              <Box>
                <Typography variant="body2">Cotação:</Typography>
                <Typography variant="body1" fontWeight="bold">
                  {owner.tpre_numero}
                </Typography>
                <Typography variant="body2">
                  {format(new Date(), 'dd/MM/yyyy')}
                </Typography>
              </Box>
            </Grid>
            <Grid {...gridItemProps} md={4}>
              <Box>
                <Typography variant="body2">Fornecedor:</Typography>
                <Typography variant="body1" fontWeight="bold">
                  {owner.emp_razao_social}
                </Typography>
                <Typography variant="body2">{owner.emp_endereco}</Typography>
              </Box>
            </Grid>
            <Grid {...gridItemProps} md={2}>
              <Box>
                <Typography variant="body2">Finaliza em:</Typography>
                <Typography variant="body1" fontWeight="bold" color="primary">
                  {getBiddingDueDate()}
                </Typography>
                <Typography variant="body2">23:59:59</Typography>
              </Box>
            </Grid>
          </Grid>
        </StyledToolbar>
      </StyledAppBar>
    </Container>
  )
}

AppBar.propTypes = {
  owner: PropTypes.object,
}
