import styled from 'styled-components'
import { AppBar, Toolbar } from '@mui/material'

export const Container = styled.nav`
  background-color: ${(props) => props.theme.palette.grey[100]};
  min-height: 200px;
  width: 100%;
  clip-path: polygon(0 0, 350% 0, 0 100%);

  ${(props) => props.theme.breakpoints.down('md')} {
    clip-path: unset;
  }
`

export const StyledAppBar = styled(AppBar)`
  .MuiGrid-item + .MuiGrid-item {
    border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
    padding-left: 30px;
    padding-right: 30px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    transform: none;

    .MuiGrid-item + .MuiGrid-item {
      padding-left: 0;
      padding-top: 15px;
      border-left: none;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    img {
      max-width: 100px;
    }
  }
`

export const StyledToolbar = styled(Toolbar)`
  padding-top: 24px;
  display: flex;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding-bottom: 24px;
  }
`
