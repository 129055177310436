const getSeverity = (status) => {
  switch (status) {
    // Unauthorized
    case 401:
      return 'warning'
    default:
      return 'error'
  }
}

export const handleErrors = (error, alternativeMessage) => {
  if (error.isAxiosError) {
    try {
      const returnedError = error.response.data.error || alternativeMessage
      const severity = getSeverity(error.response.status)

      return { msg: returnedError, severity }
    } catch (error) {
      return { msg: alternativeMessage, severity: 'error' }
    }
  } else {
    return { msg: alternativeMessage, severity: 'error' }
  }
}

export default handleErrors
